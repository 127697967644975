.info-imbal {
  background-image: url(../assets/icon/star-orange.svg),
    url(../assets/icon/star-orange.svg), url(../assets/icon/oval-blue.svg),
    url(../assets/icon/oval-purple.svg), url(../assets/icon/ellipse1.svg),
    url(../assets/icon/ellipse2.svg);
  background-position: 7% 17%, 80% 78%, 0% 85%, 100% 15%, 3% 45%, 98% 45%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat;
}

.card-est {
  background-image: url(../assets/icon/star-orange2.svg),
    url(../assets/icon/circle-intersect1.svg),
    url(../assets/icon/circle-intersect2.svg);
  background-position: 95% 85%, 100% 0%, 0% 100%;
  background-repeat: no-repeat, no-repeat, no-repeat;
}
