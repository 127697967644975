.text-field.isFocus {
  border-color: var(--primary-color);
}

.text-field label {
  display: block;
  text-overflow: ellipsis;
  transform-origin: top left;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  max-width: calc(100% - 24px);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (max-width: 450px) {
  .text-field label {
    max-width: 200px;
  }
}

.text-field label.isExpand {
  font-weight: 600;
  color: inherit;
  max-width: calc(133% - 24px);
}

.text-field-legend {
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  width: 100%;
  border-radius: inherit;
}

.text-field-legend input {
  font: inherit;
  letter-spacing: inherit;
  border-radius: inherit;
  color: var(--text-primary-color);
  border: 0;
  box-sizing: content-box;
  background-color: white;
  height: 1.4em;
  margin: 0;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 14px 16px;
}

.text-field-legend input.label-inner {
  background-color: #f5f5f5;
  height: 1.7em;
  padding: 22px 16px 4px 16px;
}

.text-field-legend .select {
  font: inherit;
  letter-spacing: inherit;
  color: var(--text-primary-color);
  border: 0;
  background: none;
  display: block;
  min-height: 1em;
  height: min-content;
  margin: 0;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 14px 16px;
}

.text-field-legend input::placeholder {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--text-tertiary2-color);
}

.text-field-legend fieldset {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  overflow: hidden;
  min-width: 0%;
}

.text-field-legend fieldset.isFocus {
  border-color: var(--primary-color);
  border-width: 1px;
}

/* .text-field-legend fieldset.error {
  border-color: rgb(var(--error-primary-color));
} */

.text-field-legend fieldset.isFocus.error {
  border-color: rgb(var(--error-primary-color));
}

.text-field-legend fieldset legend.field {
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

/* .text-field-legend fieldset.isFocus legend.field {
  max-width: 100%;
} */

.text-field-legend fieldset legend.field span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.box-otp > div > div > div fieldset {
  border-color: #9e9e9e;
  border-width: 0.5px;
}

/* checkbox circle */
.checkbox-round input[type='checkbox'] {
  visibility: hidden;
  position: absolute;
}

.checkbox-round input + span.check-round {
  position: relative;
  width: 22px !important;
  height: 22px !important;
}

.checkbox-round .checkbox:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  opacity: 0;
  position: absolute;
  left: 4px;
  top: 6px;
  transform: rotate(-45deg);
  height: 6px;
  width: 12px;
}

.checkbox-round input[type='checkbox']:checked + span.check-round {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.checkbox-round input[type='checkbox']:checked + span:after {
  opacity: 1;
}

/* Blue check radio */
.blue-check-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blue-check-radio input[type='radio'] {
  position: absolute;
  opacity: 0;
}

.blue-check-radio .radio-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.blue-check-radio input[type='radio']:checked + .radio-circle::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-image: url('../assets/icon/check-blue.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.blue-check-radio input[type='radio']:disabled + .radio-circle {
  border-color: #ededed;
}

.blue-check-radio input[type='radio']:disabled ~ span:hover {
  cursor: not-allowed;
}

.blue-check-radio input[type='radio']:disabled + .radio-circle::before {
  background-color: #fff;
}

.blue-check-radio input[type='radio']:disabled + .radio-circle::before {
  background-color: #fff;
}
