table.table-customize :where(thead) :where(th) {
  border-width: 1px 0px 1px 0px;
  border-color: var(--pressed-color);
}
table.table-customize :where(thead) :where(th):first-child {
  border-left-width: 1px;
}
table.table-customize :where(thead) :where(th):last-child {
  border-right-width: 1px;
}
table.table-customize :where(tbody) :where(td) {
  border-color: var(--border-background-color);
  border-bottom-width: 1px;
}
table.table-customize :where(tbody) :where(td):first-child {
  border-left-width: 1px;
}
table.table-customize :where(tbody) :where(td):last-child {
  border-right-width: 1px;
}
table#table-ongoing thead tr th:nth-child(6) {
  white-space: pre-wrap !important;
  text-align: center;
}
