.slick-slider.set-left .slick-list .slick-track {
  margin-left: unset !important;
}

.slick-slide > div {
  margin-right: 16px;
}

.eco-slider .slick-slide > div {
  margin-right: 0;
}

.tkb-slider .slick-slide > div {
  margin-right: 0;
  width: 165px;
}
