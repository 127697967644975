.portfolio_chart li.recharts-legend-item {
  display: flex !important;
  align-items: center;
}
.portfolio_chart span.legend-text {
  display: flex;
}
.portfolio_chart span.legend-text span:first-child {
  display: inline-block;
  width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.portfolio_chart.repayment li.recharts-legend-item {
  justify-content: unset;
}
.portfolio_chart.repayment span.legend-text {
  flex-direction: column;
  margin-left: 16px;
}

@media screen and (max-width: 375px) {
  .portfolio_chart span.legend-text span:first-child {
    max-width: 15ch;
  }
}
