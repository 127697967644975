@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'input.css';
@import 'button.css';
@import 'recharts.css';
@import 'card.css';
@import 'table.css';
@import 'carousel.css';
@import 'react-datepicker/dist/react-datepicker.css';

@layer utilities {
  .hide-scroll::-webkit-scrollbar {
    display: none;
  }
}

html {
  height: 100%;
  background-color: var(--core-background-color) !important;
}

:root,
:before,
:after {
  --accent-color: #87c054;
  --accent-variant1-color: #6daf31;
  --border-background-color: #ededed;
  --pressed-color: #e0e0e0;
  --core-background-color: #f6f8fc;
  --error-primary-color: #ee4267;
  --error-secondary-color: #ee4266;
  --error-background-color: #fef6f7;
  --success-primary-color: #0ead69;
  --success-background-color: #f3fbf7;
  --warning-primary-color: #ffd23f;
  --warning-secondary-color: #cd7b2e;
  --warning-background-color: #fffcf5;
  --warning-background-color2: #fff9f2;
  --gray-color: #8b959e;
  --light-grey-color: #d8e1f1;
  --light-grey-color2: #ededed;
  --orange1-color: #ffa070;
  --orange2-color: #f78a53;
  --orange3-color: #de8b62;
  --orange4-color: #f79f3b;
  --primary-color: #069ec6;
  --primary-1-color: #1db8e0;
  --primary-2-color: #178ead;
  --primary-variant1-color: #007999;
  --primary-variant2-color: #1db8e0;
  --primary-light-color: #e2f9ff;
  --primary-placeholder-color: #aaaaaa;
  --purple-1-color: #6b1e87;
  --purple-2-color: #540d6e;
  --purple-3-color: #431354;
  --secondary-color: #333;
  --text-primary-color: #011419;
  --text-secondary-color: #545454;
  --text-tertiary-color: #757575;
  --text-tertiary2-color: #9e9e9e;
  --text-tertiary3-color: #013c50;
  --text-tertiary4-color: #627e92;
  --ornament-1-color: #466fb2;
  --ornament-2-color: #2d569a;
  --ornament-3-color: #324f80;
  --state-hover: #f5f5f5;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: var(--text-primary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 15px;
  background-color: var(--core-background-color);
  min-height: 100vh;
}

#root {
  height: 100vh;
}

body.noscroll {
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  html,
  body {
    overflow-x: hidden;
  }

  #root.noscroll {
    padding-right: 4px !important;
  }
}

input.password {
  -webkit-text-security: disc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input.place-nunito::placeholder {
  font-family: 'Nunito Sans', sans-serif;
}

.nunito {
  font-family: 'Nunito Sans';
}

.sidebar-item {
  position: relative;
}

.sidebar-item.active:not(.children)::after {
  content: '';
  width: 4px;
  height: 100%;
  position: absolute;
  right: -21px;
  border-radius: 25px;
  top: 0;
  background-color: var(--primary-color);
}

.sidebar-item.active.children {
  position: relative;
  color: var(--secondary-color);
  border-radius: 6px;
  transition: 500ms;
  overflow: hidden !important;
}

.sidebar-item.active.children::after {
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  width: 0.25rem;
  border-radius: 6px 0 0 6px;
  background: linear-gradient(
    91.53deg,
    #2d569a 4.32%,
    #238fb7 60.93%,
    #1f8eb6 100%
  );
}

.number {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 700;
}

.excerpt-blog > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1224px) {
  .login-page {
    background-image: url(../assets/ornament/leaf.svg),
      url(../assets/ornament/plant.svg), url(../assets/ornament/oval.svg),
      url(../assets/ornament/plant2.svg), url(../assets/ornament/leaf2.svg);
    background-position: top left, bottom left, bottom center, bottom right,
      top right;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .login-page {
    background-image: url(../assets/ornament/plant.svg),
      url(../assets/ornament/plant2.svg);
    background-position: -110% 80%, 130% 100%;
    background-repeat: no-repeat, no-repeat;
    background-size: 70%, 50%;
  }
}

.login-page::before {
  content: '';
  background: linear-gradient(45deg, #eefffd 16%, #fff 51%, #def1ff 86%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  z-index: -1;
}

.login-bg {
  background-image: url(../assets/icon/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tooltip-style {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-style > span[aria-label='tooltip-text'] {
  text-transform: none;
  min-width: 120px;
  width: max-content;
  max-width: 180px;
  background-color: #545454;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-weight: normal;
  padding: 8px 10px;
  position: fixed;
  z-index: 10;
  word-break: break-word;
}

.tooltip-style > span[aria-label='tooltip-text']::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 0%;
  margin-left: -11px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #545454 transparent transparent;
}

.react-datepicker-wrapper {
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 13px 13px 11px 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #535353;
}

.react-datepicker__input-container input::placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #535353;
  font-family: 'Nunito Sans';
  font-style: normal;
}

.react-datepicker-ignore-onclickoutside:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.react-datepicker-popper {
  z-index: 10;
}

.btn-selfie button {
  width: 160px;
}

.calendar-wrapper .rbc-day-bg + .rbc-day-bg,
.calendar-wrapper .rbc-month-row + .rbc-month-row,
.calendar-wrapper .rbc-month-view,
.calendar-wrapper .rbc-header,
.calendar-wrapper .rbc-header + .rbc-header {
  border: none;
}

.calendar-wrapper .rbc-header {
  font-weight: 400;
}

.calendar-wrapper .rbc-month-header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 16px;
  margin-bottom: 15px;
  color: #9c9c9c;
  font-weight: 400;
  font-size: 12px;
}

.calendar-wrapper .rbc-month-view .rbc-row-content {
  height: 50px;
}

.calendar-wrapper .rbc-month-view .rbc-row-content .rbc-row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-wrapper .rbc-date-cell {
  text-align: center;
  font-size: 14px;
  color: #333;
}

.calendar-wrapper .rbc-off-range-bg {
  background-color: #fff;
}

.calendar-wrapper .rbc-off-range {
  background-color: #fff;
}

.calendar-wrapper .rbc-today {
  background-color: #fff;
}

.calendar-wrapper .rbc-date-cell.rbc-now {
  font-weight: unset;
}

.calendar-wrapper .rbc-toolbar {
  margin-bottom: 32px;
}

.calendar-wrapper .rbc-toolbar-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.calendar-wrapper .rbc-toolbar-item .rbc-btn-group {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.calendar-wrapper .rbc-toolbar button {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-wrapper .rbc-toolbar .rbc-toolbar-btn--prev {
  text-align: left;
}

.calendar-wrapper .rbc-toolbar .rbc-toolbar-btn--next {
  text-align: right;
}

.calendar-wrapper .rbc-toolbar button:hover,
.calendar-wrapper .rbc-toolbar button:focus,
.calendar-wrapper .rbc-toolbar button:active,
.calendar-wrapper .rbc-toolbar button:active:hover,
.calendar-wrapper .rbc-toolbar button:active:focus {
  background-color: unset;
  color: unset;
  border-color: unset;
  box-shadow: unset;
}

.calendar-wrapper .rbc-date-cell span {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .calendar-wrapper .rbc-date-cell span {
    width: 38px;
    height: 38px;
  }
}

.calendar-wrapper .rbc-date-cell span.pendanaan {
  background-color: #0ead69;
  color: #fff;
}

.calendar-wrapper .rbc-date-cell span.penyaluran {
  background-color: #f78a53;
  color: #fff;
}

.calendar-wrapper .rbc-date-cell span.pengembalian {
  background-color: #069ec6;
  color: #fff;
}

.calendar-wrapper .rbc-date-cell.rbc-off-range span {
  opacity: 0.5;
}

.breadcrumb .breadcrumb-item {
  color: var(--text-primary-color);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.4px;
  font-weight: 700;
}

.breadcrumb .breadcrumb-item:not(:last-child) {
  padding-right: 0.75rem;
  margin-right: 0.75rem;
  position: relative;
  color: var(--primary-color);
}

.breadcrumb .breadcrumb-item:not(:last-child)::after {
  content: '';
  background-image: url(../assets/icon/angle-right-gray.svg);
  position: absolute;
  background-size: contain;
  top: 3px;
  right: -6px;
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
}

/* Collapsed Sidebar */
.main-wrapper {
  transition: all 1s ease;
}

.sidebar-wrapper {
  width: 236px;
  transition: all 0.5s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-wrapper.isCollapsed {
  width: 88px;
  overflow: visible;
}

.sidebar-wrapper.isCollapsed .profile {
  padding-bottom: 0;
}

.sidebar-wrapper.isCollapsed .profile-box {
  gap: 0;
  justify-content: center;
  margin-bottom: 0;
}

.sidebar-wrapper #menu-collapse {
  transform: translateX(224px) rotate(45deg);
  transition: all 0.5s ease;
}

.sidebar-wrapper.isCollapsed #menu-collapse {
  transform: translateX(75px) rotate(45deg);
  transition: all 0.5s ease;
}

.sidebar-wrapper.isCollapsed .collapse-hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.rdf-box {
  transition: all 0.5s ease;
}

.sidebar-wrapper.isCollapsed .rdf-box {
  box-shadow: none !important;
  background: none;
  overflow: visible;
}

.sidebar-wrapper.isCollapsed .rdf-box .rdf-box-inner {
  padding: 0;
}

.sidebar-wrapper.isCollapsed .rdf-box .rdf-box-inner .rdf-box-icon {
  flex-direction: column;
  gap: 20px;
}

.riwayat-rdf {
  opacity: 0;
  display: none;
  position: absolute;
  transition: all 0.5s ease;
}

.sidebar-wrapper.isCollapsed .riwayat-rdf {
  display: flex;
  opacity: 1;
  position: relative;
}

.sidebar-wrapper.isCollapsed .sidebar-item {
  padding: 0 !important;
  justify-content: center;
}

.sidebar-wrapper.isCollapsed .sidebar-logo {
  justify-content: center;
  transition: all 2s ease;
}

.sidebar-wrapper.isCollapsed .collapse-signout {
  padding: 0;
}
.sidebar-wrapper.isCollapsed .signout-btn {
  justify-content: center;
}

.collapsed-logo-img {
  opacity: 0;
  position: absolute;
  /* transition: all 2s ease; */
}

.collapse-text {
  transition: all 1.5s ease;
}
.sidebar-wrapper.isCollapsed .collapse-text {
  transition: all 0s ease;
}
.sidebar-wrapper.isCollapsed .collapsed-logo-img {
  opacity: 1;
  position: unset;
}

/* Fund Filter */
.fund-filter .filter-item.active {
  color: #fff;
  background-color: var(--primary-color);
}

.fund-filter .filter-item.active img {
  filter: brightness(0) invert(1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Small scrollbar */
/* width */
.small-scrollbar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.small-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.small-scrollbar::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

/* Handle on hover */
.small-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.penerima-pembiayaan {
  max-height: 135px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.penerima-pembiayaan.shown {
  max-height: 9999px;
  transition: max-height 1.5s ease-in-out;
}

.show-more-wrapper::after {
  content: '';
  width: 100%;
  height: 60px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.51) 0%,
    #ffffff 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
}

.penerima-pembiayaan.shown .show-more-wrapper::after {
  content: unset;
}

/* Side Filter */
.collapse-content label .checkmark {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  border: 2px solid #ededed;
}

.collapse-content label .checkmark:after {
  content: '';
  display: none;
}

.collapse-content label:hover input ~ .checkmark {
  background-color: #ddd;
}

.collapse-content label input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.collapse-content label input:checked ~ .checkmark:after {
  display: block;
}

.collapse-content label .checkmark:after {
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  top: 0;
  margin: auto;
}

.step-bar {
  display: flex;
  flex-direction: row;
}

.step-bar li {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.step-bar li.funding-progress:not(:last-child),
.step-bar li.complete-profile:not(:last-child) {
  min-width: fit;
  margin-right: 93px;
}

.step-bar li.register-progress {
  min-width: 175px;
}

.step-bar li .progress {
  height: 28px;
  width: 28px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-bar li .progress {
  border: 1px solid var(--pressed-color);
  background-color: white;
}

.step-bar li.funding-progress .progress.active {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: white;
}

.step-bar li.register-progress .progress.active {
  border: 1px solid var(--accent-color);
  background-color: var(--accent-color);
  color: white;
}

.step-bar li.complete-profile .progress.active {
  border: 1px solid var(--accent-color);
  background-color: var(--accent-color);
  color: white;
}

.step-item.complete-profile.active::before {
  background-color: var(--accent-color);
}

.step-item + .step-item:before {
  content: '';
  height: 4px;
  top: 13px;
  position: absolute;
  background: var(--border-background-color);
  border-radius: 4px;
}

.step-item + .step-item.funding-progress:before {
  left: -85px;
  width: 76px;
}

.step-item + .step-item.complete-profile:before {
  left: -95px;
  width: 96px;
}

.step-item + .step-item.register-progress:before {
  left: -65px;
  width: 75%;
}

.step-item-mobile::before {
  content: '';
  height: 6px;
  width: 6px;
  left: 4.5%;
  margin-top: 40px;
  position: absolute;
  background: var(--border-background-color);
  border-radius: 4px;
}

.step-item-mobile.active::before {
  background: var(--accent-color);
}

/* Input Range Ujroh */
.ujroh-input-range .input-range__slider {
  background-color: #017b9c;
  border-color: #017b9c;
}
.ujroh-input-range .input-range__track {
  background-color: #e2eff9;
}
.ujroh-input-range
  .input-range__track
  .input-range__slider-container:last-child
  .input-range__label {
  top: -1.8rem;
}
.ujroh-input-range .input-range__track--active {
  background-color: #017b9c;
}
.ujroh-input-range .input-range__label {
  font-family: 'Nunito Sans', sans-serif;
}
.ujroh-input-range .input-range__label--value {
  top: unset;
  bottom: -1.8rem;
}
.ujroh-input-range .input-range__label--value .input-range__label-container {
  color: #017392;
}
.ujroh-input-range .input-range__label--min {
  left: -23px;
  bottom: -2px;
}
.ujroh-input-range .input-range__label--max {
  right: -39px;
  bottom: -2px;
}
.ujroh-input-range .input-range__label-container {
  font-size: 11px;
  font-weight: 700;
  color: #606060;
}
.ujroh-input-range .input-range__label--max .input-range__label-container {
  left: 0;
}

.voucher-list ol {
  list-style: auto;
  padding-inline-start: 24px;
}

.voucher-list ul {
  list-style: disc;
  padding-inline-start: 48px;
}

.voucher-list ol li ul {
  list-style: disc;
  padding-inline-start: 26px;
}

.info-password ul {
  list-style: disc;
  padding-inline-start: 20px;
}

/* Voucher */
.voucher-item::before {
  content: '';
  width: 10px;
  height: 20px;
  position: absolute;
  background-color: #f6f8fd;
  bottom: 52px;
  left: -2px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border: 1px solid transparent;
  border-left: 0;
}

.voucher-item::after {
  content: '';
  width: 10px;
  height: 20px;
  position: absolute;
  background-color: #f6f8fd;
  bottom: 52px;
  right: -2px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border: 1px solid transparent;
  border-right: 0;
}

.voucher-tnc ol {
  list-style: decimal;
  padding-left: 1.5rem;
}
.voucher-tnc ol li {
  list-style: decimal;
}
.voucher-tnc ul {
  padding-left: 1rem;
  list-style-type: disc;
}
.voucher-tnc ul li {
  list-style-type: disc;
}

/* Vertical StepProgress */
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}
.StepProgress-item {
  position: relative;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}
.StepProgress-item::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}
.StepProgress-item::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -35px;
  width: 13px;
  height: 13px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  background-color: #fff;
}
.StepProgress-item.step-from::before {
  border-left: 3px solid var(--pressed-color);
}
.StepProgress-item.step-from::after {
  content: '';
  font-size: 10px;
  color: #fff;
  text-align: center;
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
}
.StepProgress-item.step-to::after {
  content: '';
  padding-top: 1px;
  width: 13px;
  height: 13px;
  top: 0;
  left: -35px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #87c054;
  background-color: #87c054;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.info-box ul {
  list-style-type: disc;
  padding-left: 24px;
}

.StepProgress-empty .step:first-child::before {
  content: '';
  height: 2px;
  display: block;
  position: absolute;
  top: 8px;
  border-bottom: 2px solid var(--primary-color);
}

.StepProgress-empty .step:nth-child(2)::before {
  content: '';
  height: 2px;
  display: block;
  position: absolute;
  top: 8px;
  border-bottom: 2px dashed var(--pressed-color);
}

@media screen and (max-width: 810px) {
  .step-bar li.funding-progress:not(:last-child),
  .step-bar li.complete-profile:not(:last-child) {
    min-width: fit;
    margin-right: 80px;
  }
}

@media screen and (max-width: 640px) {
  .StepProgress-empty .step {
    flex: 0 0 125px;
  }
  .StepProgress-empty .step p {
    height: 2.75rem;
  }
  .StepProgress-empty .step:first-child::before {
    width: 115%;
    right: -109px;
  }
  .StepProgress-empty .step:nth-child(2)::before {
    width: 115%;
    right: -109px;
  }
}

@media screen and (max-width: 540px) {
  .StepProgress-empty .step {
    flex: 0 0 100px;
  }
  .StepProgress-empty .step:first-child::before {
    width: 100%;
    right: -90px;
  }
  .StepProgress-empty .step:nth-child(2)::before {
    width: 100%;
    right: -90px;
  }
}

@media screen and (max-width: 450px) {
  .StepProgress-empty .step {
    flex: 0 0 100px;
  }
  .StepProgress-empty .step:first-child::before {
    width: 85%;
    right: -63px;
  }
  .StepProgress-empty .step:nth-child(2)::before {
    width: 85%;
    right: -63px;
  }
}

@media screen and (max-width: 400px) {
  .StepProgress-empty .step {
    flex: 0 0 100px;
  }
  .StepProgress-empty .step:first-child::before {
    width: 85%;
    right: -60px;
  }
  .StepProgress-empty .step:nth-child(2)::before {
    width: 85%;
    right: -63px;
  }
}

.tooltip::before {
  font-size: 13px;
  line-height: 18px;
}

#line-timeline::after {
  left: var(--afterLeft);
  height: var(--afterHeight);
}

.faq-answer p {
  margin-bottom: 0.75rem;
}

.faq-answer ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
}

.faq-answer ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
}

.register {
  background-image: url('../assets/icon/alami-vector2.svg'),
    url('../assets/icon/star-blue.svg'), url('../assets/icon/muslim.svg'),
    url('../assets/icon/star-blue.svg');
  background-position: left top 72px, left 136px top 77px, left 512px top 143px,
    left 418px top 398px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
}

.radial-progress.verify-progress:before {
  background: radial-gradient(farthest-side, currentColor 98%, #0000)
      top/var(--thickness) var(--thickness) no-repeat,
    conic-gradient(currentColor calc(var(--value) * 1%), #ffffff66 0);
}

.return-dashboard {
  background: linear-gradient(133.56deg, #067391 2.88%, #03566c 97.12%);
}

.return-dashboard .rectangle {
  background-image: url('../assets/icon/mask-return.svg');
  background-position: left -16px top 0;
  background-repeat: no-repeat, no-repeat, no-repeat;
}

.check-with-label:checked ~ .label-for-check svg {
  transform: rotate(180deg);
}

.check-with-label:checked ~ .label-for-check .label-text span:first-child {
  display: none;
}
.check-with-label:checked
  ~ .label-for-check
  .label-text
  span:not(:first-child) {
  display: block;
}

label .checkbox-main {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  border: 3px solid #b6c1c3;
}

label .checkbox-main:after {
  content: '';
  display: none;
}

label:hover input ~ .checkbox-main {
  background-color: #ddd;
}

label input:checked ~ .checkbox-main {
  background-color: var(--primary-color);
  border: 1px solid #ededed;
}

label input:checked ~ .checkbox-main.secondary {
  background-color: var(--accent-variant1-color);
  border: 1px solid #ededed;
}

label input:checked ~ .checkbox-main:after {
  display: block;
}

label input:disabled ~ .checkbox-main {
  background-color: var(--light-grey-color2);
}

label input:disabled ~ .checkbox-main.secondary {
  background-color: var(--light-grey-color2);
}

label .checkbox-main:after {
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  top: 0;
  margin: auto;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

/* React slick bug fix on safari browser */
@media screen and (max-width: 600px) {
  .slick-slider .slick-track {
    display: flex;
  }
  .slick-slide img {
    width: auto !important;
  }
  .slick-initialized .slick-slide {
    width: fit-content !important;
  }
  .slick-slider.campaign-slider .slick-list {
    min-height: 260px !important;
  }
}

/* File Upload */

.dropzone {
  border: 2px dashed var(--border-background-color);
  text-align: center;
}

.dropzone.active {
  border-color: var(--primary-color);
}

/* info box */
.info-box-primary {
  border-radius: 12px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-light-color);
  padding: 12px;
  height: fit-content;
  min-height: 22px;
}
.info-box-base {
  border-radius: 12px;
  background-color: var(--core-background-color);
  padding: 12px;
  height: fit-content;
  min-height: 22px;
}

.dotted-spaced {
  background-image: linear-gradient(
    to right,
    #9e9e9e 40%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 17px 1px;
  background-repeat: repeat-x;
}

.dotted-spaced2 {
  background-image: linear-gradient(
    to right,
    #9e9e9e 60%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}
